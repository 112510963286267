/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum Fnma34CitizenshipDesignation {
  usCitizen = 'USCitizen',
  permanentNonResident = 'PermanentResidentAlien',
  nonPermanentNonResident = 'NonPermanentResidentAlien',
}

export enum Fnma34MaritalStatus {
  Married = 'Married',
  Separated = 'Separated',
  Unmarried = 'Unmarried',
}

export enum Fnma34TitleHeldDesignation {
  solely = 'Sole',
  jointWithSpouse = 'JointWithSpouse',
  jointWithOtherThanSpouse = 'JointWithOtherThanSpouse',
  NotSpecified = '',
}

export enum Fnma34PropertyUse {
  PrimaryResidence = 'PrimaryResidence',
  SecondaryOrVacation = 'SecondHome',
  InvestmentOrRental = 'Investment',
  NotSpecified = '',
}

export enum Fnma34HousingExpenseType {
  expense_first_mortgage = 'FirstMortgagePrincipalAndInterest',
  expense_second_mortgage = 'OtherMortgageLoanPrincipalAndInterest',
  expense_hoa_dues = 'HomeownersAssociationDuesAndCondominiumFees',
  expense_home_insurance = 'HomeownersInsurance',
  expense_mortgage_insurance = 'MIPremium',
  expense_property_taxes = 'RealEstateTax',
  proposed_expense_first_mortgage = 'FirstMortgagePrincipalAndInterest',
  proposed_expense_second_mortgage = 'OtherMortgageLoanPrincipalAndInterest',
  proposed_expense_hoa_dues = 'HomeownersAssociationDuesAndCondominiumFees',
  proposed_expense_home_insurance = 'HomeownersInsurance',
  proposed_expense_mortgage_insurance = 'MIPremium',
  proposed_expense_property_taxes = 'RealEstateTax',
}

export enum Fnma34AmortizationType {
  AdjustableRate = 'AdjustableRate',
  FixedRate = 'Fixed',
  Other = 'Other',
}

export enum Fnma34EthnicityType {
  HispanicOrLatino = 'HispanicOrLatino',
  NotHispanicOrLatino = 'NotHispanicOrLatino',
}

export enum Fnma34EthnicityOriginType {
  Cuban = 'Cuban',
  Mexican = 'Mexican',
  Other = 'Other',
  PuertoRican = 'PuertoRican',
}

export enum Fnma34GenderType {
  Male = 'Male',
  Female = 'Female',
  MaleAndFemale = 'ApplicantSelectedBothMaleAndFemale',
}

export enum Fnma34RaceType {
  AmericanIndianOrAlaskaNative = 'AmericanIndianOrAlaskaNative',
  Asian = 'Asian',
  BlackOrAfricanAmerican = 'BlackOrAfricanAmerican',
  NativeHawaiianOrOtherPacificIslander = 'NativeHawaiianOrOtherPacificIslander',
  White = 'White',
}

export enum Fnma34RaceDesignationType {
  AsianIndian = 'AsianIndian',
  Chinese = 'Chinese',
  Filipino = 'Filipino',
  GuamanianOrChamorro = 'GuamanianOrChamorro',
  Japanese = 'Japanese',
  Korean = 'Korean',
  NativeHawaiian = 'NativeHawaiian',
  OtherAsian = 'OtherAsian',
  OtherPacificIslander = 'OtherPacificIslander',
  Samoan = 'Samoan',
  Vietnamese = 'Vietnamese',
}

export enum Fnma34CashOutDeterminationType {
  CashOut = 'CashOut',
  LimitedCashOut = 'LimitedCashOut',
  NoCashOut = 'NoCashOut',
}

export enum Fnma34IncomeType {
  Base = 'Base',
  Overtime = 'Overtime',
  Bonus = 'Bonus',
  Commissions = 'Commissions',
}

export enum BorrowerResidencyBasisType {
  Own = 'Own',
  Rent = 'Rent',
  LivingRentFree = 'LivingRentFree',
}
