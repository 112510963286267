//Source: https://www.mortgagecalculator.org/helpful-advice/property-taxes.php
export const StateEscrowFees = {
  AK: 0.96,
  AL: 0.48,
  AR: 0.67,
  AZ: 0.68,
  CA: 0.77,
  CO: 0.52,
  CT: 2.0,
  DC: 0.64,
  DE: 0.56,
  FL: 1.05,
  GA: 0.86,
  HI: 0.32,
  IA: 1.43,
  ID: 1.02,
  IL: 2.13,
  IN: 0.94,
  KS: 1.47,
  KY: 0.74,
  LA: 0.71,
  MA: 1.21,
  MD: 1.05,
  ME: 1.43,
  MI: 1.52,
  MN: 1.06,
  MO: 1.11,
  MS: 0.86,
  MT: 0.8,
  NC: 0.78,
  ND: 1.11,
  NE: 1.5,
  NH: 2.03,
  NJ: 2.31,
  NM: 0.97,
  NV: 0.64,
  NY: 1.88,
  OH: 1.68,
  OK: 0.89,
  OR: 1.02,
  PA: 1.89,
  RI: 1.64,
  SC: 0.63,
  SD: 1.38,
  TN: 0.54,
  TX: 2.06,
  UT: 0.65,
  VA: 0.99,
  VT: 2.02,
  WA: 0.88,
  WI: 1.61,
  WV: 0.57,
  WY: 0.76,
}

//Source: https://homeinsurance.com/rates-in-your-state/
export const StateHomeownersInsuranceAverage = {
  AK: 1103,
  AL: 2328,
  AR: 2405,
  AZ: 1283,
  CA: 1031,
  CO: 2412,
  CT: 1481,
  DC: 1035,
  DE: 850,
  FL: 2133,
  GA: 1619,
  HI: 442,
  IA: 1583,
  ID: 982,
  IL: 1443,
  IN: 1395,
  KS: 3535,
  KY: 2053,
  LA: 2193,
  MA: 1203,
  MD: 1211,
  ME: 961,
  MI: 1176,
  MN: 1773,
  MO: 2377,
  MS: 2530,
  MT: 2042,
  NC: 1794,
  ND: 2128,
  NE: 3547,
  NH: 876,
  NJ: 934,
  NM: 1661,
  NV: 975,
  NY: 1262,
  OH: 1265,
  OK: 4053,
  OR: 840,
  PA: 851,
  RI: 1388,
  SC: 2196,
  SD: 2288,
  TN: 2033,
  TX: 3087,
  UT: 784,
  VA: 1100,
  VT: 733,
  WA: 1133,
  WI: 1084,
  WV: 1366,
  WY: 1171,
}
