import { Environment } from './environment.d'

export const environment: Environment = {
  production: process.env.APP_ENV === 'prod',
  environment: process.env.APP_ENV,
  essent: {
    url: process.env.ESSENT_URL,
    apiKey: process.env.ESSENT_API_KEY,
    lenderIdentifier: process.env.ESSENT_LENDER_IDENTIFIER,
    transactionIdentifier: process.env.ESSENT_TRANSACTION_IDENTIFIER,
  },
  errorLog: {
    accessKeyId: process.env.ERROR_LOGGING_BUCKET_ACCESS_KEY_ID,
    secretAccessKey: process.env.ERROR_LOGGING_BUCKET_SECRET_KEY,
    region: 'us-east-1',
    bucketName: process.env.ERROR_LOGGING_BUCKET_NAME,
  },
  productName: process.env.PRODUCT_NAME,
  uiBaseUrl: process.env.UI_BASE_URL,
  apiBaseUrl: process.env.API_BASE_URL,
  aiApiBaseUrl: process.env.AI_API_BASE_URL, // Ngrok url if testing the ringcentral webhooks
  admin: {
    authToken: process.env.ADMIN_AUTH_TOKEN,
    uiUrl: process.env.ADMIN_UI_URL,
  },
  adminApi: {
    baseUrl: process.env.ADMIN_API_BASE_URL,
    token: process.env.ADMIN_AUTH_TOKEN,
  },
  auth0: {
    domain: process.env.AUTH0_DOMAIN,
    apiAudience: process.env.AUTH0_API_AUDIENCE,
    clientId: process.env.AUTH0_CLIENT_ID_QUESTIONNAIRE,
    secret: process.env.AUTH0_CLIENT_SECRET_QUESTIONNAIRE,
    borrowerPortalClientId: process.env.AUTH0_CLIENT_ID_BORROWER_PORTAL_API,
    borrowerPortalSecret: process.env.AUTH0_CLIENT_SECRET_BORROWER_PORTAL_API,
    // There can be multiple connection configured in a given Auth0 tenant. Add
    // connections here as they are added (i.e. a social identity provider)
    dbConnections: {
      userPass: process.env.AUTH0_USER_PASS_DB_CONNECTION,
    },
    audience: process.env.AUTH0_AUDIENCE,
  },
  adminApiAuth0: {
    domain: process.env.ADMIN_API_AUTH0_DOMAIN,
    clientId: process.env.ADMIN_API_AUTH0_CLIENT_ID,
    clientSecret: process.env.ADMIN_API_AUTH0_CLIENT_SECRET,
    audience: process.env.ADMIN_API_AUTH0_AUDIENCE,
  },
  documentExtract: {
    accessKeyId: process.env.AWS_ACCESS_KEY_ID_LE_COMPARISON,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY_LE_COMPARISON,
    region: 'us-east-1',
    bucketName: process.env.BUCKET_NAME_LE_COMPARISON,
    bedrockAccessKey: process.env.BEDROCK_ACCESS_KEY,
    bedrockSecretKey: process.env.BEDROCK_SECRET_KEY,
  },
  calcService: {
    baseUrl: process.env.CALCULATOR_SERVICE_BASE_URL,
  },
  certifiedCredit: {
    apiUrl:
      process.env.CERTIFIED_CREDIT_API_URL ||
      'https://demo.mortgagecreditlink.com/inetapi/request_products.aspx',
    interfaceIdentifier:
      process.env.CERTIFIED_CREDIT_INTERFACE_IDENTIFIER ||
      'SmartAPITestingIdentifier',
    username: process.env.CERTIFIED_CREDIT_USERNAME,
    password: process.env.CERTIFIED_CREDIT_PASSWORD,
    prequalUsername: process.env.CERTIFIED_CREDIT_PREQUAL_USERNAME,
    prequalPassword: process.env.CERTIFIED_CREDIT_PREQUAL_PASSWORD,
  },
  coreLogicAVM: {
    username: process.env.CORELOGIC_AVM_USERNAME,
    password: process.env.CORELOGIC_AVM_PASSWORD,
    baseUrl: process.env.CORELOGIC_AVM_URL,
  },
  coreLogicCreditReport: {
    accountId: process.env.CORELOGIC_CREDIT_REPORT_ACCOUNT_ID,
    accountPassword: process.env.CORELOGIC_CREDIT_REPORT_ACCOUNT_PASSWORD,
    baseUrl: process.env.CORELOGIC_CREDIT_REPORT_URL,
    // In some cases we need to hit the production env
    // mainly to allow reissuing to work in Encompass on test
    baseUrlProd: process.env.CORELOGIC_CREDIT_REPORT_URL_PROD,
    accountIdProd: process.env.CORELOGIC_CREDIT_REPORT_ACCOUNT_ID_PROD,
    accountPasswordProd:
      process.env.CORELOGIC_CREDIT_REPORT_ACCOUNT_PASSWORD_PROD,
    pfxPath: process.env.CORELOGIC_CREDIT_REPORT_PFX_PATH,
    pfxPassPhrase: process.env.CORELOGIC_CREDIT_REPORT_PFX_PASSPHRASE,
  },
  ice: {
    url: process.env.ICE_URL || 'undefined-url',
    username: process.env.ICE_USERNAME || 'undefined-username',
    password: process.env.ICE_PASSWORD || 'undefined-password',
  },
  launchDarkly: {
    sdkKey: process.env.LAUNCH_DARKLY_SDK_KEY,
  },
  make: {
    baseUrl: 'https://ingest.make.rvapps.io',
    writeKey: process.env.MAKE_WRITEKEY,
    sourceUid: process.env.MAKE_SOURCE_UID,
  },
  mq: {
    baseUrl: process.env.MQ_API_BASE_URL,
    lockUrl: process.env.MQ_API_LOCK_URL,
    apiKey: process.env.MQ_API_API_KEY,
    lockApiKey: process.env.MQ_API_LOCK_API_KEY,
    advertiserId: process.env.MQ_ADVERTISER_ID,
    lockAdvertiserId: process.env.MQ_LOCK_ADVERTISER_ID,
  },
  ringCentral: {
    url: process.env.RING_CENTRAL_URL,
    basicToken: process.env.RING_CENTRAL_BASIC_TOKEN,
    jwtToken: process.env.RING_CENTRAL_JWT_TOKEN,
    username: process.env.RING_CENTRAL_USERNAME,
    password: process.env.RING_CENTRAL_PASSWORD,
    extensionNumber: process.env.RING_CENTRAL_EXTENSION,
    clientId: process.env.RING_CENTRAL_CLIENT_ID,
    clientSecret: process.env.RING_CENTRAL_CLIENT_SECRET,
    recordingsRegion: process.env.REGION,
    recordingsS3Bucket:
      process.env.RC_RECORDINGS_S3_BUCKET || 'undefined-bucket',
    recordingsAccessKeyId: process.env.RC_RECORDINGS_ACCESS_KEY_ID || '',
    recordingsSecretAccessKey:
      process.env.RC_RECORDINGS_SECRET_ACCESS_KEY || '',
  },
  rollbar: {
    enabled: process.env.ROLLBAR_ENABLED,
    token: process.env.ROLLBAR_TOKEN_API,
  },
  slack: {
    url: 'https://hooks.slack.com',
    alfieTechWebhookRoute: process.env.SLACK_ALFIE_TECH_WEBHOOK_ROUTE,
    alfieReportingWebhookRoute: process.env.SLACK_ALFIE_REPORTING_WEBHOOK_ROUTE,
    elloTechAlertWebhookRoute: process.env.SLACK_ELLO_TECH_WEBHOOK_ROUTE,
    floatWebhookRoute: process.env.SLACK_FLOAT_WEBHOOK_ROUTE,
    lockWebhookRoute: process.env.SLACK_LOCK_WEBHOOK_ROUTE,
    ntlWebhookRoute: process.env.SLACK_NTL_WEBHOOK_ROUTE,
    leadWebhookRoute: process.env.SLACK_LEAD_WEBHOOK_ROUTE,
    processorNotificationsWebhookRoute:
      process.env.SLACK_PROCESSOR_NOTIFICATIONS_WEBHOOK_ROUTE,
    cicRequestNotification:
      process.env.SLACK_CIC_REQUEST_NOTIFICATIONS_WEBHOOK_ROUTE,
    loNotificationsWebhookRoute:
      process.env.SLACK_LO_NOTIFICATIONS_WEBHOOK_ROUTE,
    nmlsReportWebhookRoute: process.env.NMLS_REPORT_NOTIFICATION_BOT,
    encompassErrorsWebhookRoute:
      process.env.SLACK_ENCOMPASS_ERRORS_WEBHOOK_ROUTE,
    verificationReportAlertWebhookRoute:
      process.env.SLACK_VERIFICATION_REPORT_WEBHOOK_ROUTE,
    doesNotQualifyNotification:
      process.env.SLACK_DOES_NOT_QUALIFY_NOTIFICATION_WEBHOOK_ROUTE,
    postMessageRoute: process.env.SLACK_POST_MESSAGE_ROUTE,
    shlcLeadBotToken: process.env.SLACK_SHLC_LEAD_BOT_TOKEN,
    lookupUserRoute: process.env.SLACK_LOOKUP_USER_ROUTE,
  },
  sns: {
    accessKeyId: process.env.VELOCIFY_SNS_ACCESS_KEY,
    secretAccessKey: process.env.VELOCIFY_SNS_SECRET_ACCESS_KEY,
    region: process.env.REGION,
  },
  storyBlok: {
    // Dev tool - Set baseUrl to null to fall back to your local questionSet.ts
    // baseUrl: null,
    baseUrl: 'https://api.storyblok.com',
    token: process.env.STORYBLOK_TOKEN,
    version: process.env.STORYBLOK_VERSION,
  },
  velocify: {
    url: process.env.VELOCIFY_URL,
    username: process.env.VELOCIFY_USERNAME,
    password: process.env.VELOCIFY_PASSWORD,
  },
  vgsOutboundProxy: {
    host: process.env.VGS_OUTBOUND_PROXY_HOST,
    username: process.env.VGS_OUTBOUND_PROXY_USERNAME,
    password: process.env.VGS_OUTBOUND_PROXY_PASSWORD,
    cert: process.env.VGS_OUTBOUND_PROXY_CERT,
  },
  googleMaps: {
    url: 'https://maps.googleapis.com',
    apiKey: process.env.GOOGLE_MAPS_API_KEY,
  },
  googleStreetView: {
    url: 'https://maps.googleapis.com',
    apiKey: process.env.GOOGLE_STREET_VIEW_API_KEY,
    secret: process.env.GOOGLE_STREET_VIEW_SECRET,
  },
  googleAddressValidation: {
    baseUrl: process.env.GOOGLE_ADDRESS_VALIDATION_BASE_URL,
    apiKey: process.env.GOOGLE_ADDRESS_VALIDATION_API_KEY,
  },
  iterable: {
    baseUrl: process.env.ITERABLE_BASE_URL,
    apiKey: process.env.ITERABLE_API_KEY,
  },
  redis: {
    host: process.env.REDIS_HOST,
  },
  retool: {
    baseUrl: process.env.RETOOL_URL,
  },
  documentStore: {
    bucketName: process.env.BP_DOCUMENT_STORE_BUCKET,
    accessKeyId: process.env.BP_DOCUMENT_STORE_ACCESS_KEY_ID,
    secretAccessKey: process.env.BP_DOCUMENT_STORE_SECRET_ACCESS_KEY,
    region: process.env.REGION,
  },
  generatedDocumentStore: {
    bucketName: process.env.GENERATED_DOCUMENT_BUCKET,
    accessKeyId: process.env.GENERATED_DOCUMENT_ACCESS_KEY_ID,
    secretAccessKey: process.env.GENERATED_DOCUMENT_SECRET_ACCESS_KEY,
    region: process.env.REGION,
  },
  internalDocumentsStore: {
    bucketName: process.env.INTERNAL_DOCUMENTS_BUCKET,
    accessKeyId: process.env.INTERNAL_DOCUMENTS_BUCKET_ACCESS_KEY_ID,
    secretAccessKey: process.env.INTERNAL_DOCUMENTS_BUCKET_SECRET_KEY,
    region: process.env.REGION,
  },
  encompass: {
    username: process.env.ENCOMPASS_USERNAME,
    password: process.env.ENCOMPASS_PASSWORD,
    clientId: process.env.ENCOMPASS_CLIENT_ID,
    clientSecret: process.env.ENCOMPASS_CLIENT_SECRET,
    apiUrl: process.env.ENCOMPASS_API_URL,
    webhookSigningKey: process.env.ENCOMPASS_SIGNING_KEY,
    loanFolder: process.env.ENCOMPASS_LOAN_FOLDER,
    templatePath: process.env.ENCOMPASS_TEMPLATE_PATH,
  },
  encompassSdk: {
    apiUrl: process.env.ENCOMPASS_SDK_API_URL,
    apiUsername: process.env.ENCOMPASS_SDK_API_USER,
    apiPassword: process.env.ENCOMPASS_SDK_API_PASSWORD,
  },
  docusign: {
    baseUrl: process.env.DOCUSIGN_BASE_URL,
    oAuthUrl: process.env.DOCUSIGN_OAUTH_URL,
    clientId: process.env.DOCUSIGN_CLIENT_ID,
    userId: process.env.DOCUSIGN_USER_ID,
    base64PrivateKey: process.env.DOCUSIGN_PRIVATE_KEY_BASE64,
    apiAccountId: process.env.DOCUSIGN_API_ACCOUNT_ID,
    webhookSigningKey: process.env.DOCUSIGN_SIGNING_KEY,
  },
  elloSync: {
    accessKeyId: process.env.ELLO_SYNC_ACCESS_KEY_ID,
    secretAccessKey: process.env.ELLO_SYNC_SECRET_ACCESS_KEY,
    region: process.env.REGION,
    queueUrl: process.env.ELLO_SYNC_PROCESSOR_QUEUE_URL,
    deadLetterUrl: process.env.ELLO_SYNC_PROCESSOR_DEAD_LETTER_URL,
  },
  encompassWebhook: {
    accessKeyId: process.env.ENCOMPASS_WEBHOOK_ACCESS_KEY_ID,
    secretAccessKey: process.env.ENCOMPASS_WEBHOOK_SECRET_ACCESS_KEY,
    region: process.env.REGION,
    queueUrl: process.env.ENCOMPASS_WEBHOOK_PROCESSOR_QUEUE_URL,
    deadLetterUrl: process.env.ENCOMPASS_WEBHOOK_PROCESSOR_DEAD_LETTER_URL,
  },
  monarch: {
    url: 'https://monarch.cohesionapps.com/api',
    clientToken: process.env.MONARCH_TOKEN,
    sourceId: process.env.MONARCH_API_SOURCE_ID,
    loTemplateRuleId: process.env.MONARCH_LO_TEMPLATE_RULE_ID,
  },
  fannieMae: {
    partnerId: '10000034',
    institutionId: process.env.FANNIE_MAE_INSTITUTION_ID,
    username: process.env.FANNIE_MAE_AUS_USERNAME,
    password: process.env.FANNIE_MAE_AUS_PASSWORD,
  },
  silk: {
    url: 'https://orders.silktitleco.com',
    sageProductId: 178341,
    apiKey: process.env.SILK_API_KEY,
  },
  nexuss: {
    url: 'https://clients.unisourcejv.com',
    username: 'titlequotes',
    password: process.env.NEXUSS_PASSWORD,
    sagePartnerId: 534342,
  },
  finicity: {
    apiUrl: 'https://api.finicity.com',
    appKey: process.env.FINICITY_APP_KEY,
    partnerId: process.env.FINICITY_PARTNER_ID,
    partnerSecret: process.env.FINICITY_PARTNER_SECRET,
    webhookUrl: process.env.FINICITY_WEBHOOK_URL,
  },
  dropCowboy: {
    url: 'https://api.dropcowboy.com',
    callbackUrl: process.env.DROP_COWBOY_CALLBACK_URL,
    teamId: process.env.DROP_COWBOY_TEAM_ID,
    secret: process.env.DROP_COWBOY_SECRET,
    brandId: process.env.DROP_COWBOY_BRAND_ID,
  },
  emailProcessor: {
    region: process.env.REGION,
    queueUrl: process.env.EMAIL_PROCESSOR_QUEUE_URL,
    subscriptionClientState: process.env.SUBSCRIPTION_CLIENT_STATE,
    tenantId: process.env.EMAIL_PROCESSOR_TENANT_ID,
    clientId: process.env.EMAIL_PROCESSOR_CLIENT_ID,
    clientSecret: process.env.EMAIL_PROCESSOR_CLIENT_SECRET,
    redisDb: Number(process.env.EMAIL_PROCESSOR_REDIS_DB || 2),
    s3Bucket: process.env.EMAIL_PROCESSOR_S3_BUCKET,
    webHookUrl: process.env.EMAIL_PROCESSOR_WEBHOOK_URL,
  },
  freddieMac: {
    sellerServicerNumber: process.env.FREDDIE_MAC_SELLER_SERVICER_NUMBER,
    username: process.env.FREDDIE_MAC_AUS_USERNAME,
    password: process.env.FREDDIE_MAC_AUS_PASSWORD,
    partnerId: '10000035',
  },
  reggora: {
    baseUrl: process.env.REGGORA_BASE_URL,
    apiKey: process.env.REGGORA_API_KEY,
    username: process.env.REGGORA_USERNAME,
    password: process.env.REGGORA_PASSWORD,
  },
  vgs: {
    adminHost: process.env.VGS_ADMIN_API_BASE_URL,
  },
}
